<template>
  <ContentWrapper :links="topLinks">
    <Form inline>
      <FormItem>
        <Button type="primary" @click="$router.push({ name: 'GroupbuyCreatePage' })">发布</Button>
      </FormItem>
    </Form>
    <Table stripe :columns="columns" :data="itemList" :loading="tableLoading"></Table>
    <Pager :total="itemListCount" :current.sync="page" />
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import Pager from "@/components/Pager.vue"
import { queryGroupbuy, closeGroupbuy } from "@/api"
import "@/components/tableUtil"

export default {
  name: "GroupbuyQueryPage",
  components: { ContentWrapper, Pager },
  data() {
    return {
      topLinks: [{ title: "集单列表", link: this.$route.path }],
      page: 1,
      tableLoading: false,
      itemList: [],
      itemListCount: 0,
      columns: [
        { title: "编号", key: "alias", width: 100, maxWidth: 200, fixed: "left" },
        {
          title: "标题",
          maxWidth: 200,
          width: 200,
          render: (h, params) => {
            return h(
              "a",
              {
                on: {
                  click: () => {
                    this.$router.push({ name: "GroupbuyCreatePage", query: { alias: params.row.alias } })
                  },
                },
              },
              [params.row.name],
            )
          },
          fixed: "left",
        },
        {
          title: "状态",
          key: "status",
          render: (h, p) => {
            const status = p.row.status === 2 ? "已关闭" : p.row.startTime > new Date().getTime() ? "未开始" : p.row.expireTime < new Date().getTime() ? "已结束" : "进行中"
            return h("div", status)
          },
        },
        {
          title: "开始时间",
          key: "createdAt",
          render: (h, p) => {
            return h("DateHolder", {
              props: { time: p.row.startTime, defText: "-" },
            })
          }
        },
        {
          title: "过期时间",
          render: (h, p) => {
            return h("DateHolder", {
              props: { time: p.row.expireTime, defText: "-" },
            })
          },
        },
        {
          title: "操作",
          width: 250,
          render: (h, params) => {
            return h("ButtonGroup", [
              h(
                "Button",
                {
                  on: {
                    click: () => {
                      this.$router.push({ name: "GroupbuyCreatePage", query: { alias: params.row.alias } })
                    },
                  },
                  props: { type: "primary" }
                },
                "查看"
              ),
              h(
                "Button",
                {
                  props: { type: "warning", disabled: params.row.status === 2},
                  on: {
                    click: () => {
                      this.$Modal.confirm({
                        content: "确认关闭？",
                        onOk: () => { 
                          this.closeButton(params.row.alias)
                        }
                      })
                    },
                  },
                },
                "关闭",
              )])
          }
        },
      ]
    }
  },
  computed: {},
  watch: {
    async page() {
      await this.reload()
    }
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {
      try {
        this.tableLoading = true
        const { items, count } = await queryGroupbuy({
          page: this.page
        })

        this.itemList = items
        this.itemListCount = count
        this.tableLoading = false
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    async closeButton(groupbuyAlias) {
      console.log(groupbuyAlias)
      try {
        await closeGroupbuy({
          groupbuyAlias: groupbuyAlias
        })
        this.reload()
      } catch (e) {
        this.$Message.error(e.message)
      }
    }
  }
  ,
}
</script>
